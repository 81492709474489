<template>
  <icon-base
    :width="width"
    :height="height"
    icon-name="hive"
    icon-color="url(#linear-gradient)"
    class="hive loading"
  >
    <brand-symbol />
    <template v-slot:defs>
      <linearGradient id="linear-gradient" x1="-100%" y1="-100%" x2="200%" y2="200%" >
        <stop offset="0" stop-color="#26272C">
          <animate attributeName="offset" values="0;.5;0" dur="4s" repeatCount="indefinite"  /> 
        </stop>
        <stop offset=".5" stop-color="#698DF2">
          <animate attributeName="offset" values=".33;.66;.33" dur="4s" repeatCount="indefinite"  /> 
        </stop>
        <stop offset="1" stop-color="#26272C">
          <animate attributeName="offset" values=".66;1;.66" dur="4s" repeatCount="indefinite"  /> 
        </stop>
      </linearGradient>
    </template>
  </icon-base>
</template>

<style>

/* .hive.loading path {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: hiveLoading 16s ease infinite;
}

@keyframes hiveLoading {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */

</style>

<script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: 20
      },
      height: {
        type: [Number, String],
        default: 24
      },
    },
    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
    }
  }
</script>